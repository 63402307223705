import React from 'react';
import PropTypes from 'prop-types';

const ProjectFactSheet = ({ facts }) => (
  <div className="flex flex-wrap justify-start bg-grey-lightest p-12 mt-8 text-lg">
    {facts.map((fact, idx) => (
      <div
        key={`project-fact-${fact.title}`}
        className={`flex flex-col w-full md:w-auto ml-0 ${idx !== 0 ? 'mt-8 md:mt-0' : ''} ${idx === 0 ? '' : 'lg:ml-32 md:ml-8'}`}>
        <div className="font-bold uppercase mb-4">{fact.title}</div>
        <div className="flex flex-row flex-wrap">
          {fact.text}
        </div>
      </div>
    ))}
  </div>
);

ProjectFactSheet.propTypes = {
  facts: PropTypes.array.isRequired,
};

export default ProjectFactSheet;
