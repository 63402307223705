import React from 'react';

import Layout from '../../components/Layout';
import HeaderSection from '../../components/sections/HeaderSection';
import SEO from '../../components/SEO';
import HeaderImage from '../../components/images/works/city-link/Header';
import TaskList from '../../components/pages/works/TaskList';
import ProjectFactSheet from '../../components/pages/works/ProjectFactSheet';
import TimeLine from '../../components/sections/TimeLine';
import PhoneIcon from '../../images/icons/phone.svg';
import RocketIcon from '../../images/icons/rocket.svg';
import LightBulbIcon from '../../images/icons/light-bulb.svg';
import IPhonesImage from '../../components/images/works/city-link/IPhones';
import NativeScript from '../../images/career/technologies/nativescript.png';
import Firebase from '../../images/career/technologies/firebase-large.svg';
import Technologies from '../../components/pages/works/Technologies';


const CityLink = () => (
  <Layout>
    <SEO title="City Link" keywords={[]} />
    <HeaderSection>
      <>
        <h1>
          City Link
        </h1>
        <h2>
          Prototyp-Entwicklung einer Smart City App
        </h2>
        <div className="flex flex-row w-full mt-8">
          <p className="w-full md:w-2/3">
            Mit City Link haben wir einen Prototyp für eine App entwickelt, die Einwohnern die digitale Nutzung des städtischen Angebotes ermöglicht.
            Zielsetzung war es, mit dem Prototypen weitere Kooperationspartner für das Projekt zu gewinnen.
          </p>
        </div>
      </>
    </HeaderSection>
    <div className="flex flex-col">
      <div className="flex flex-row w-full flex-wrap mt-2">
        <div className="flex flex-col w-full md:w-3/5">
          <HeaderImage />
        </div>
        <TaskList
          tasks={[
            {
              name: 'Produktmanagement',
              icon: LightBulbIcon,
            },
            {
              name: 'Backend Entwicklung',
              icon: RocketIcon,
            },
            {
              name: 'iOS & Android App',
              icon: PhoneIcon,
            },
          ]}
        />
      </div>
      <ProjectFactSheet
        facts={[
          {
            title: 'Kunde',
            text: 'items',
          },
          {
            title: 'Zeitraum',
            text: 'Mai 2018',
          },
          {
            title: 'Technologien',
            text: (
              <Technologies images={[
                NativeScript,
                Firebase,
              ]}
              />
            ),
          },
        ]}
      />
      <div className="flex flex-col mt-8">
        <div className="flex flex-row flex-wrap w-full">
          <TimeLine
            className="w-full md:w-1/2"
            steps={[
              {
                time: 'Woche 1',
                text: 'Produktplanungs-Workshop',
              },
              {
                time: 'Woche 2',
                text: 'Produktkonzept',
              },
              {
                time: 'Woche 3 & 4',
                text: 'Entwicklung & Testing',
              },
              {
                time: '',
                text: 'Prototyp',
              },
            ]}
          />
          <div className="flex flex-col w-full md:w-1/2 text-justify md:text-left">
            <h3 className="my-4">
              Projektvorgehen
            </h3>
            <p>
              hochzehn hat das Produktkonzept, Design und die Entwicklung des Prototypen für items übernommen. In einem ersten Produktplanungs-Workshop wurden auf Basis der Kundenidee mögliche Features einer städtischen App analyisiert und priorisiert. Anhand der Ergebnisse des Workshops konnte dann ein Produktkonzept inklusive UI Design erstellt werden.
              <br />
              In der 2 wöchigen Entwicklungsphase wurde daraufhin der Prototyp für eine iOS und Android App erstellt, der die wichtigsten Kernfeatures beeinhaltet.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center text-justify">
        <div className="flex flex-col mt-8 md:w-2/3">
          <h3 className="my-4">
            Technische Details
          </h3>
          <p>
            Wir haben den Prototyp mit NativeScript auf Angular-Typescript-Stack für Android und iOS als native App entwickelt. Fun-Fact: Dieser Stack war neu für uns, und nach wenigen Tagen Einarbeitung konnten wir den Prototyp entwickeln und gleichzeitig den Hunger nach neuen Technologien stillen.
            <br />
            <br />
            Im Backend kam die Firebase Realtime Database als NoSQL-Datenbank zum Einsatz. Insbesondere die Echtzeit-Synchronisation aller App-Clients bei Datenänderungen vermittelte in Demonstrationen des Prototyps das Gefühl, eine nahezu fertige App in der Hand zu halten.
            <br />
            <br />
            Wegen der kurzen Zeit für die Fertigstellung des Prototypen haben wir einige Abkürzungen genommen: Tests und besonders sauberer Code blieben auf der Strecke, beides hätte sich innerhalb der Entwicklungsphase nicht rentiert. Stattdessen hatten wir rapide Code-Integration, Commits gingen oft direkt in den master-Zweig. Fehlende Automatisierung der Builds wurde durch entsprechende Dokumentation aufgefangen.
          </p>
        </div>
        <div className="flex flex-row flex-wrap mt-16 justify-between md:w-2/3">
          <div className="flex flex-col w-full">
            <IPhonesImage />
          </div>
          <div className="flex flex-row flex-wrap-reverse w-full md:justify-end mt-16 text-justify">
            <div className="flex flex-col justify-center">
              <p>
                Ralf Leufkes,
                <br />
                Innovation Management items GmbH:
              </p>
              <div className="mt-4 text-xl font-light italic">
                „Mit hochzehn haben wir einen Partner gefunden, der uns bei dem gesamten Prozess der Produktentwicklung unterstützt und geholfen hat. Vom Verstehen des Problems, dem Ausloten von möglichen Lösungsansätzen – immer mit einem nutzerzentrischen Blick – und den ersten prototypischen Produktansätzen, alles aus einer Hand. Hervorzuheben ist in dem gesamten Prozess der klare Fokus auf unsere Bedürfnisse gewesen – hochzehn war immer Moderator aber nie Richtungsgeber. Auf diesem Wege Evaluierung – Problemidentifizierung – Personas und Userjourneys – Prototyping – sind wir mit CityLink zu einem Ergebnis gekommen auf das wir ohne die Unterstützung von hochzehn niemals gekommen wären. Einfach klasse!“
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CityLink;
