import React from 'react';
import PropTypes from 'prop-types';

function ImageRow(props) {
  const { images, rowIndex, additionalClasses } = props;
  let imageIndex = 0;
  return (
    <div key={rowIndex} className={`flex flex-row ${additionalClasses}`}>
      {images.map((image) => {
        const imageKey = `tech-img-${rowIndex}-${imageIndex += 1}`;
        return (
          <div className="text-center">
            <img key={imageKey} src={image} className="h-6 px-2" alt="" />
          </div>
        );
      })}
    </div>
  );
}

ImageRow.propTypes = {
  images: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  additionalClasses: PropTypes.string.isRequired,
};

function Technologies(props) {
  const { images, maxLogoPerRow, lineSpacing } = props;
  const rowCount = Math.ceil(images.length / maxLogoPerRow);
  const imageRows = [];
  for (let row = 0; row < rowCount; row += 1) {
    imageRows[row] = images.slice(row * maxLogoPerRow, (row + 1) * maxLogoPerRow);
  }
  return (
    <div className={`flex flex-wrap -mt-${lineSpacing} -mx-2`}>
      {imageRows.map((imageRow, idx) => (
        <ImageRow rowIndex={idx} images={imageRow} additionalClasses={`mt-${lineSpacing}`} />
      ))}
    </div>
  );
}

Technologies.propTypes = {
  images: PropTypes.array.isRequired,
  maxLogoPerRow: PropTypes.number,
  lineSpacing: PropTypes.number,
};

Technologies.defaultProps = {
  maxLogoPerRow: 3,
  lineSpacing: 4,
};

export default Technologies;
