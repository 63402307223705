import React from 'react';
import PropTypes from 'prop-types';

const TimeLine = ({ steps, className }) => (
  <div className={`flex flex-col p-4 ${className}`}>
    {steps.map((step, idx) => {
      const isFirst = idx === 0;
      const isLast = idx === steps.length - 1;
      const key = `time-line-step-${idx}`;

      return (
        <div key={key} className="flex flex-row w-full items-center justify-center">
          <div className={`flex-no-shrink text-right text-grey w-1/5 font-bold mt-1 ${isFirst ? 'self-start' : ''} ${isLast ? 'self-end' : ''}`}>
            {step.time}
          </div>
          <div className="flex-no-shrink flex flex-col justify-center items-center mx-3">
            {!isFirst ? (<div className="h-5 w-px bg-grey" />) : null}
            <div className={`w-6 h-6 rounded-full border-2 border-grey bg-white ${!isFirst ? 'mt-2' : ''} ${!isLast ? 'mb-2' : ''}`} />
            {!isLast ? (<div className="h-5 w-px bg-grey" />) : null}
          </div>
          <div className={`flex-grow items-center font-bold mt-1 ${isFirst ? 'self-start' : ''} ${isLast ? 'self-end text-brand' : ''}`}>
            {step.text}
          </div>
        </div>
      );
    })}
  </div>
);

TimeLine.propTypes = {
  steps: PropTypes.array.isRequired,
  className: PropTypes.string,
};

TimeLine.defaultProps = {
  className: '',
};

export default TimeLine;
