import React from 'react';
import PropTypes from 'prop-types';

const TaskList = ({ tasks }) => (
  <div className="flex flex-col mt-6 md:mt-0 md:p-16 font-bold text-xl justify-center">
    {tasks.map((task, idx) => (
      <div
        key={`task-list-entry-${idx}`}
        className={`flex flex-row items-center flex-wrap ${idx !== 0 ? 'mt-4' : ''}`}
      >
        <img src={task.icon} className="w-8 h-8" alt="Icon" />
        <span className="ml-8">{task.name}</span>
      </div>
    ))}
  </div>
);

TaskList.propTypes = {
  tasks: PropTypes.array.isRequired,
};

export default TaskList;
